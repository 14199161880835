*,
::before,
::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

ul {
	list-style-type: none;
}

a {
	color: inherit;
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}

@media screen and (max-width: 700px) {
	p {
		font-size: 1.2rem
	}
}